import logo from './images/logo.png';
import Show051723 from './images/Shows/05-17-23.jpg';
import Show070923 from './images/Shows/07-09-23.jpg';
import Show102623 from './images/Shows/10-26-23.jpg';
import Show031524 from './images/Shows/03-15-24.jpg';
import Show033024 from './images/Shows/03-30-24.jpg';
import Show071824 from './images/Shows/07-18-24.jpg';
import Show080324 from './images/Shows/08-03-24.jpg';
import { SocialIcon } from 'react-social-icons';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{fontStyle: "italic"}}>Check out our single "Calling Your Name"</p>
      </header>
      <div className="bandcamp-player">
      <iframe 
        title="Calling Your Name - Single by Mad Gods"
        style={{border: 0, width: "350px", maxWidth:"100vw", height: "470px"}} 
        src="https://bandcamp.com/EmbeddedPlayer/album=397262956/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" 
        seamless
      >
        <a href="https://madgods.bandcamp.com/album/calling-your-name-single">
          Calling Your Name - Single by Mad Gods
        </a>
      </iframe>
      </div>
      <div className="Shows">
      <h2 className="About">
          Mad Gods is an alternative rock band, formed in 2020, from Portland, OR.
        </h2>
        <p className="Lineup">Matthew Gordon (Vocals & Guitar) / Xeno Miller (Drums) / Matthew Kellog (Guitar) / Michael OLeary Jr (Bass)</p>
        <h2>Shows</h2>
        <p>Come see us!</p>
      </div>
      <div className="Shows">
        <img src={Show080324} className="Content" alt="Show at Bridge City Sessions on 08-03-24" />
        <img src={Show071824} className="Content" height="300" width="250" alt="Show at No Fun Bar on 07-18-24" />
        <img src={Show033024} className="Content" alt="Show at Breakside Brewery on 03-30-24" />
        <img src={Show031524} className="Content" alt="Show at Twilight on 03-15-23" />
        <img src={Show102623} className="Content" alt="Show at Dante's on 10-26-23" />
        <img src={Show070923} className="Content" alt="Show at No Fun Bar on 07-09-23" />
        <img src={Show051723} className="Content" alt="Show at High Water Mark on 05-17-23" />
      </div>
      <div className="Youtube">
        <h2>Our Music</h2>
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/9jFJL0C-VPI?si=Zs1vjANDTMY84yuI" 
          title="Breakside Brewery Saturday March 30 2024" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  
          allowfullscreen>
        </iframe>
        <iframe
          className="Content"
          width="560"
          height="315"
          src="https://youtube.com/embed/KJC3G2eDi9w?si=E50PsSQ2I3J75KOL"
          title="Burnin Sun - March 15 Promo"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        >
        </iframe>
        <iframe 
          className="Content"
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/LNu5VMfqBRc?si=UjcjaCr2W0p9uzDA" 
          title="Calling Your Name" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen
        />
        <iframe 
          className="Content"
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/acPlrLJkS6I?si=SgZ8wHP45e8ji-ad" 
          title="Mary Jane" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen
        />
        <iframe 
          className="Content"
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/wspehUUsFRY?si=S6zMBvgS3yRBTP_5" 
          title="Burnin Sun" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen 
        />
      </div>

      <div>
        <h2>Socials</h2>
          <SocialIcon className="Content" url="https://www.facebook.com/madgodspdx/"/>
          <SocialIcon className="Content" url="https://www.instagram.com/madgodspdx/"/>
          <SocialIcon className="Content" url="https://www.youtube.com/@MadGodsBand"/>
      </div>

      <div>
        <br/>
        Copyright © 2023 Mad Gods Band ®
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  );

}

export default App;
